import { buildUrl } from "../../helpers/apiHelper";
import axios from "../../lib/axios"

const getCompanies = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/companies';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const getRoles = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/roles';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const getContactRoles = async (
    config
) => {
    const baseUrl = '/api/insights-contact-roles';

    return await axios
        .get(baseUrl, config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response);
            return []
        });
}

const getMenuItems = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/sidebar-menu';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const getPalletTypes = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/pallet-types';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const getFeatures = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/s/features';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return {}
        })
}

const getPermissions = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/permissions';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const getLogisticsTypes = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/logistics-types';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const getCreditTerms = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    const baseUrl = '/api/qb-credit-terms';

    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => {
            return data.map((term) => ({
                id: term.Id,
                name: term.Name
            }))
        })
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const globalAPI = {
    getCompanies,
    getRoles,
    getContactRoles,
    getMenuItems,
    getPalletTypes,
    getFeatures,
    getPermissions,
    getLogisticsTypes,
    getCreditTerms
}

export default globalAPI

import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import { Link, useParams, useNavigate } from 'react-router-dom'

import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import { useAuth } from "../../hooks/auth";

const UpdateContacts = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, contactRoles, pusher } = useStateContext()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()

    const [contact, setContact] = useState([])

    const [name, setName] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [siteId, setSiteId] = useState('')

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getContact(params.id)
        setCompanyId(localStorage.getItem('company_id'))
        setSiteId(localStorage.getItem('site'))
    }, [params.id])

    const getContact = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/insights-contacts/${id}`, config)
            .then(res => {
                const contact = res.data
                setName(contact.name)
                setPhone(contact.phone)
                setEmail(contact.email)
                setRole(contact.role)
                setContact(contact)
                setIsLoading(false)
            })
    }

    const updateContact = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['phone'] = phone
        formData['role'] = role
        formData['company_id'] = companyId
        formData['site_id'] = siteId

        await axios.put(`/api/insights-contacts/${params.id}`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            setIsLoading(false)
        }).catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setIsLoading(false)
        })
    }


    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_contact')} - {name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to='/contacts'><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>

                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full h-fit'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('phone')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('email')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                        </div>

                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('role')} *</p>
                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select value={role} onChange={(e) => { setRole(e.target.value) }}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}>
                                        {contactRoles?.map((role, index) => (
                                            <MenuItem key={`contact-role-i-${index}`} value={role.id}>{t(role.slug)}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                    </div>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateContact} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateContacts

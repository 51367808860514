import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Autocomplete, Box, FormControl, ListItem, ListItemText, MenuItem, Modal, Select, Stack, TextField, Tooltip} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useNavigate, useLocation, useParams, Link} from "react-router-dom";
import { toNumber, round, has, isNull, isNil, includes } from 'lodash';
import { useDebounce } from 'use-debounce';

import {useStateContext} from '../../../context/ContextProvider'
import axios from '../../../lib/axios';
import { createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleNumber, handleZero, isValid } from '../../../helpers/helper'
import Materials from '../../../components/inventory/Items';
import AddButton from '../../../components/AddButton';
import Loading from '../../../components/Loading';
import CommentHistoryButton from '../../../components/CommentHistoryButton';
import CommentHistoryModal from '../../../components/CommentHistoryModal';
import { useAuth } from '../../../hooks/auth';
import { AUTOCOMPLETE_STYLE } from '../../../constants/constants';

const UpdateProductionOrder = () => {
    const { t } = useTranslation()
    const { config, currencySymbol, pusher } = useStateContext()
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useAuth({ middleware: 'guest' })

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    /* state */
    const [count, setCount] = useState(10000)
    const [random, setRandom] = useState(Math.random())
    const [customers, setCustomers] = useState([])
    const [items, setItems] = useState([])
    const [productionSupervisors, setProductionSupervisors] = useState([])
    const [materialsList, setMaterialsList] = useState([])
    const [materials, setMaterials] = useState([])
    const [commentHistory, setCommentHistory] = useState([])
    const [item, setItem] = useState({})
    const [totalCost, setTotalCost] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [siteId, setSiteId] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [hasEnabledMaterials, setHasEnabledMaterials] = useState(false)
    const [statusName, setStatusName] = useState('')

    const [openCommentHistory, setOpenCommentHistory] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isDisabled, setIsDisabled] = useState(true)

    /* form */
    const [customerId, setCustomerId] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [productionOrderNo, setProductionOrderNo] = useState('')
    const [productionOrderDescription, setProductionOrderDescription] = useState('')
    const [itemId, setItemId] = useState('')
    const [itemNo, setItemNo] = useState('')
    const [itemUnitOfMeasureCode, setItemUnitOfMeasureCode] = useState('')
    const [startDate, setStartDate] = useState(dayjs().endOf('day'))
    const [startTime, setStartTime] = useState(dayjs().endOf('minute'))
    const [dueDate, setDueDate] = useState(dayjs().endOf('day'))
    const [productionSupervisorId, setProductionSupervisorId] = useState('')
    const [productionSupervisorName, setProductionSupervisorName] = useState('')
    const [comments, setComments] = useState('')
    const [qtyToProduce, setQtyToProduce] = useState(1)
    const [debouncedQtyToProduce] = useDebounce(qtyToProduce, 250)

    /* PUSHER */
    useEffect(() => {
        const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
        const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)

        channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                getProductionOrder({
                    id: data.id,
                    dueToPusherEvent: true
                })
            }
        })
        channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/production-order-board', {
                    replace: true
                })
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-${localStorage.getItem('site')}`)
        })
    }, [])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* UseEffect */

    useEffect(() => {
        if (!startDate.isSame(dueDate) && startDate.isAfter(dueDate)) {
            setDueDate(startDate)
        }
    }, [startDate, dueDate])

    useEffect(() => {
        getProductionOrder({
            id: params.id
        })
    }, [params.id])

    useEffect(() => {
        if(isValid(companyId)) {
            getCustomers(companyId)
            getItems(companyId)
        }
    }, [companyId])

    useEffect(() => {
        if(isValid(siteId)) {
            getProductionSupervisors(siteId)
        }
    }, [siteId])

    useEffect(() => {
        if(isValid(itemId)) {
            getPotentialSubAssemblies(itemId)
        }
    }, [itemId])

    useEffect(() => {
        if(has(item, 'bill_of_material_lines')) {
            setMaterials(item.bill_of_material_lines?.map((i) => {
                return {
                    ...i,
                    item_id: i.sub_assembly_id,
                    qty: i.qty_per_assembly * qtyToProduce
                }
            }))
        }
    }, [item])

    useEffect(() => {
        if(materials.length > 0) {
            const updatedMaterials = materials.map((m) => {
                return {
                    ...m,
                    qty: m.qty_per_assembly * debouncedQtyToProduce
                }
            })
            setMaterials(updatedMaterials)
        }
    }, [debouncedQtyToProduce])

    useEffect(() => {
        calculateTotalCost()
        calculateTotalQuantity()
    }, [materials])

    useEffect(() => {
        setHasEnabledMaterials(materialsList.find((mlitem) => mlitem.disabled !== true))
    }, [materials, materialsList])

    /* Handlers */

    const handleClearAllMaterials = (e) => {
        e.preventDefault()

        /**
         * For some reason, this got triggered by pressing Enter inside any of the enabled Lines inputs.
         * event.detail should be at least 1 to indicate that there has been a click and NOT a key press
         */
        if(e.detail > 0) {
            setMaterials([])
            setMaterialsList(prev => {
                const disabledListMaterials = prev.filter(m => m.disabled)
                disabledListMaterials.forEach(material => {
                    material.disabled = false
                });
                return prev
            })
            setTotalCost(0)
            setTotalQty(0)
        }
    }

    const handleAddMaterial = (e) => {
        e.preventDefault()

        if(e.detail > 0) {
            let counter = 0
            let msgMaterial = ''
            let msgQty = ''
            let msgBoth = t('please_select_material_and_enter_qty')
            materials.forEach(element => {
                if (element.item_no == '') {
                    counter += 1
                    msgMaterial = t('please_select_material')
                }
                if (element.qty_per_assembly == '' || toNumber(element.qty_per_assembly) < 1) {
                    counter += 1
                    msgQty = t('please_enter_qty')
                }
            })

            if (counter > 0) {
                if (msgMaterial !== '' && msgQty === '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgMaterial,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }
                if (msgQty !== '' && msgMaterial === '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgQty,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }
                if (msgQty !== '' && msgMaterial !== '') {
                    Swal.fire({
                        icon: "warning",
                        text: msgBoth,
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                }


                return
            }

            const material = {
                "id": count,
                "item_id": '',
                "sub_assembly_id": '',
                "sub_assembly_no": '',
                "sub_assembly_unit_of_measure_code": '',
                "description": '',
                "unit_price": 0,
                "qty_per_assembly": 0,
                "qty": 0,
                "scrap": 0,
            }

            setMaterials((prev) => [...prev, material])
            setCount(prev => prev + 1)
        }
    }

    const handleSelectMaterial = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.item_id = value.id
        material.sub_assembly_id = value.id
        material.sub_assembly_no = value.item_no
        material.sub_assembly_unit_of_measure_code = value.unit_of_measure?.code ?? ''
        material.description = value.description
        material.unit_price = value.unit_price

        setMaterials(materialsCopy)
    }

    const handleMaterialQtyPerAssembly = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.qty_per_assembly = value
        material.qty = value * qtyToProduce

        setMaterials(materialsCopy)

        calculateTotalQuantity()
    }

    const handleMaterialScrap = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.scrap = value

        setMaterials(materialsCopy)
    }

    const handleMaterialUnitPrice = (value, id) => {
        const materialsCopy = [...materials]
        const material = materialsCopy.find(m => m.id === id)

        material.unit_price = value

        setMaterials(materialsCopy)
    }

    const handleChangeItemToProduce = (item) => {
        setItemId(item?.id || '');
        setItemNo(item?.item_no || '');
        setItemUnitOfMeasureCode(item?.unit_of_measure_name || '');

        if(has(item, 'bill_of_material_lines')) {
            setMaterials(item?.bill_of_material_lines?.map((i) => {
                return {
                    ...i,
                    item_id: i.sub_assembly_id,
                    qty: i.qty_per_assembly * qtyToProduce
                }
            }))
        }
    }

    const calculateTotalCost = () => {
        let tcost = 0
        materials.forEach(material => {
            tcost += (toNumber(material.qty_per_assembly) * toNumber(material.unit_price)) * toNumber(qtyToProduce)
        })
        setTotalCost(round(tcost, 2))
    }

    const calculateTotalQuantity = () => {
        let tqty = 0
        materials.forEach(material => {
            tqty += toNumber(material.qty_per_assembly) * toNumber(qtyToProduce)
        })
        setTotalQty(tqty)
    }

    /* API Requests */
    const getProductionOrder = async ({id = null, dueToPusherEvent = false} = {}) => {
        setIsLoading(true)

        await axios
            .get(`/api/production-orders/${isNull(id) ? params.id : id}?with=site,productionSupervisor,productionOrderItems.item`, config)
            .then(res => {
                const data = res.data

                setSiteId(data?.site?.id)
                setCompanyId(data?.site?.company_id)
                setProductionOrderNo(data.no)
                setProductionOrderDescription(data.description)
                setCustomerId(data?.customer?.id)
                setCustomerName(data?.customer?.name)
                setProductionSupervisorId(data?.production_supervisor?.id)
                setProductionSupervisorName(data?.production_supervisor?.name)
                setStartDate(dayjs(data?.start_date))
                setStartTime(dayjs(data?.start_time))
                setDueDate(dayjs(data?.due_date))
                setStatusName(data?.status?.name)
                setIsDisabled(
                    (Number(data?.status?.id ?? data?.status) >= 3)
                    || (
                        !includes(['master_admin', 'office_manager'], user?.role)
                        && !userHasPermissions(['production-orders-update'])
                    )
                )

                setCommentHistory(data.comment_history)

                setItemId(data?.item?.id)
                setItemNo(data?.item?.item_no)
                setItemUnitOfMeasureCode(data?.item?.unit_of_measure?.code)
                setQtyToProduce(data?.qty_to_produce)
                setMaterials(data?.production_order_items.map((item) => ({
                    id: item.id,
                    item_id: item.id,
                    sub_assembly_id: item.item?.id,
                    sub_assembly_no: item.item?.item_no,
                    sub_assembly_unit_of_measure_code: item.item?.unit_of_measure_code,
                    description: item.item?.description,
                    unit_price: item.unit_price,
                    qty: item.qty,
                    qty_per_assembly: item.qty_per_assembly,
                    scrap: item.scrap
                })))

                if(!dueToPusherEvent) {
                    setComments('')
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (companyId) => {
        setIsLoading(true)

        await axios
            .get(`/api/customers?company_id=${companyId}&blocked=0`, config)
            .then(res => {
                const customers = res.data
                setCustomers(customers)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getItems = async (companyId) => {
        setIsLoading(true)

        await axios
            .get(`/api/items?company_id=${companyId}&with=billOfMaterialLines`, config)
            .then(res => {
                const data = res.data.filter((item) => item.item_type_key != 'other')
                setItems(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPotentialSubAssemblies = async (itemId) => {
        setIsLoading(true)

        await axios.get(`/api/items/${itemId}/potential-sub-assemblies`, config)
            .then(res => {
                const data = res.data
                setMaterialsList((data.map((d) => ({...d, disabled: false}))))
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getProductionSupervisors = async (siteId) => {
        setIsLoading(true)

        await axios
            .get(`/api/users?site_id=${siteId}&role=production_supervisor`, config)
            .then(res => {
                const data = res.data?.data
                setProductionSupervisors(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateProductionOrder = async ({shouldProceedAnyway = false} = {}) => {
        setIsLoading(true)
        const formData = {}

        formData['no'] = productionOrderNo
        formData['description'] = productionOrderDescription
        formData['qty_to_produce'] = qtyToProduce
        formData['start_date'] = dayjs(startDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['start_time'] = dayjs(startTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['due_date'] = dayjs(dueDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = siteId
        formData['item_id'] = itemId
        formData['customer_id'] = customerId
        formData['production_supervisor_id'] = productionSupervisorId
        formData['comments'] = comments
        formData['production_order_items'] = materials.map((m) => ({
            item_id: m.item?.id || m.sub_assembly_id,
            item_no: m.item?.item_no || m.sub_assembly_no,
            qty: m.qty ?? 0,
            qty_per_assembly: m.qty_per_assembly ?? 0,
            scrap: m.scrap ?? 0,
            unit_price: m.unit_price ?? 0
        }))
        formData['proceed_anyway'] = shouldProceedAnyway

        await axios
            .put(`/api/production-orders/${params.id}`, formData, config)
            .then(( data ) => {
                if(data.status === 202) {
                    Swal.fire({
                        title: t('caution'),
                        text: data.data.accepted?.description ?? '',
                        icon: 'warning',
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FEB806',
                        reverseButtons: true,
                        showCancelButton: true,

                        confirmButtonText: t('yes'),
                        cancelButtonText: t('negatory')
                    }).then((result) => {
                        if(result.isConfirmed) {
                            updateProductionOrder({
                                shouldProceedAnyway: true
                            })
                        }
                    })
                } else {
                    Swal.fire({
                        icon: "success",
                        text: data.data.success.message,
                        customClass: 'success',
                        showCloseButton: true,
                        iconColor: '#00B78E'
                    })
                    setComments('')
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const materialsColumns = [
        {
            field: 'sub_assembly_no',
            headerName: t('item_number_abbr') + ' *',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialNo params={params} t={t} materialsList={materialsList} setMaterialsList={setMaterialsList} handleSelectMaterial={handleSelectMaterial} isDisabled={isDisabled} />
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialDescription params={params} t={t} />
        },
        {
            field: 'qty_per_assembly',
            headerName: t('qty_per_assembly') + ' *',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialQtyPerAssembly params={params} t={t} materialsList={materialsList} handleMaterialQtyPerAssembly={handleMaterialQtyPerAssembly} isDisabled={isDisabled}  />
        },
        {
            field: 'qty',
            headerName: t('totalQty'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialQty params={params} t={t} qtyToProduce={qtyToProduce} />,
            valueGetter: (params) => params.row.qty_per_assembly * qtyToProduce,
            sortComparator: (v1, v2) => toNumber(v1) - toNumber(v2)
        },
        {
            field: 'sub_assembly_unit_of_measure_code',
            headerName: t('unit_of_measure'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitOfMeasure params={params} t={t} />
        },
        {
            field: 'scrap',
            headerName: `${t('scrap')} (%)`,
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialScrap params={params} t={t} materialsList={materialsList} handleMaterialScrap={handleMaterialScrap} isDisabled={isDisabled} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialUnitPrice params={params} t={t} materialsList={materialsList} currencySymbol={currencySymbol} handleMaterialUnitPrice={handleMaterialUnitPrice} isDisabled={isDisabled} />
        },
        {
            field: 'total_price',
            headerName: t('total_price'),
            flex: 1,
            minWidth: 140,
            renderCell: (params) => <MaterialTotalPrice params={params} t={t} currencySymbol={currencySymbol} calculateTotalCost={calculateTotalCost} qtyToProduce={qtyToProduce} isDisabled={isDisabled} materialsList={materialsList} />,
            valueGetter: (params) => (params.row.qty_per_assembly * params.row.unit_price) * qtyToProduce,
            sortComparator: (v1, v2) => toNumber(v1) - toNumber(v2)
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            cellClassName: 'padding-0',
            renderCell: (params) => <MaterialActions params={params} materials={materials} setMaterials={setMaterials} setMaterialsList={setMaterialsList} isDisabled={isDisabled} />
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between gap-3 w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#1ab800] text-[35px] mr-4'>•</span> {isDisabled ? t('view_production_order') : t('update_production_order')} - {productionOrderNo} <span className='mx-3 text-[#D4D4D4] font-light'>|</span> <span className='font-normal'>{statusName}</span> </p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={() => navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='p-5 pb-12 flex justify-between gap-3 flex-col'>
                    <div
                        className='bg-white p-6 pb-[12px] pt-0 rounded-md w-full h-fit'
                        style={{
                            boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                        }}
                    >
                        <div className='pb-6 pt-1 flex gap-3 items-center'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>

                        <div className='flex flex-col md:flex-row gap-0 md:gap-4'>
                            <div className='flex flex-col w-full md:w-1/2'>
                                {/* Customer */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disabled={isDisabled}
                                            disablePortal
                                            disableClearable
                                            id="customer-autocomplete"
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            options={customers?.map((option) => ({ id: option.id, label: option.name }))}
                                            value={{ id: customerId, label: customerName }}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key ={`customer-${option.id}`}>
                                                        {option.label}
                                                    </li>
                                                )
                                            }}
                                            onChange={(a, b) => {setCustomerId(b?.id || ''); setCustomerName(b?.label || '')} }
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </div>

                                {/* Production Order No. */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('production_order_no')} *</p>
                                    <div className='w-full'>
                                        <TextField
                                            disabled={isDisabled}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '9px !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            type="text"
                                            name="production_order_no"
                                            value={productionOrderNo}
                                            onChange={(e) => { setProductionOrderNo(e.target.value) }}
                                        />
                                    </div>
                                </div>

                                {/* Production Order Description */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('production_order_description')} *</p>
                                    <div className='w-full'>
                                        <TextField
                                            disabled={isDisabled}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '9px !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            type="text"
                                            name="production_order_description"
                                            value={productionOrderDescription}
                                            onChange={(e) => { setProductionOrderDescription(e.target.value) }}
                                        />
                                    </div>
                                </div>

                                {/* Item To Produce */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('item_to_produce')} *</p>
                                    <Autocomplete
                                        disabled={isDisabled}
                                        disablePortal
                                        disableClearable
                                        id="item-autocomplete"
                                        sx={AUTOCOMPLETE_STYLE}
                                        value={{ id: itemId, label: itemNo }}
                                        options={items?.map((option) => ({ id: option.id, label: option.item_no, description: option?.description, disabled: false, item: option }))}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key ={`item-option-${option.id}`}>
                                                    <ListItemText primary={option.label} secondary={option.description} />
                                                </li>
                                            )
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(a, b) => handleChangeItemToProduce(b?.item || {}) }
                                    />
                                </div>

                                {/* Quantity To Produce */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('quantity_to_produce')} *</p>
                                    <Stack direction='row'>
                                        <div className='w-2/3'>
                                            <TextField
                                                disabled={isDisabled}
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-root': {
                                                        borderTopRightRadius: '0px',
                                                        borderBottomRightRadius: '0px',
                                                    },
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                name="quantity_to_produce"
                                                value={qtyToProduce}
                                                onChange={(e) => handleInteger({
                                                    event: e,
                                                    setter: setQtyToProduce
                                                }) }
                                                onBlur={e => handleZero({
                                                    event: e,
                                                    setter: setQtyToProduce
                                                })}
                                                onFocus={e => deleteZero({
                                                    event: e,
                                                    setter: setQtyToProduce,
                                                    value: qtyToProduce
                                                })}
                                            />
                                        </div>
                                        <div className='w-1/3'>
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-root': {
                                                        borderTopLeftRadius: '0px',
                                                        borderBottomLeftRadius: '0px'
                                                    },
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                type="text"
                                                name="unit_of_measure"
                                                value={itemUnitOfMeasureCode ?? ''}
                                                disabled
                                            />
                                        </div>
                                    </Stack>
                                </div>
                            </div>

                            <div className='flex flex-col w-full md:w-1/2'>
                                <div className='lg:flex gap-3 w-full'>
                                    {/* Start Date */}
                                    <div className='w-full lg:w-1/2 pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('start_date')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    maxDate={dueDate}
                                                    disabled={isDisabled}
                                                    sx={{
                                                        width: '100%',
                                                        background: '#FCFCFC',
                                                        '.MuiOutlinedInput-input': {
                                                            padding: '9px !important'
                                                        },
                                                        boxShadow:
                                                        'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: '1px solid #EEEFF2 !important'
                                                        }
                                                    }}
                                                    format="YYYY-MM-DD"
                                                    value={startDate}
                                                    onChange={(newValue) => setStartDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    {/* Start Time */}
                                    <div className='w-full lg:w-1/2 pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('start_time')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                    <MobileTimePicker
                                                        disabled={isDisabled}
                                                        sx={{
                                                            width: '100%',
                                                            background: '#FCFCFC',
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '9px !important'
                                                            },
                                                            boxShadow: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #EEEFF2 !important'
                                                            }
                                                        }}
                                                        value={startTime}
                                                        onChange={(newValue) => setStartTime(newValue)}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>

                                {/* Due Date */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('due_date')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                disabled={isDisabled}
                                                minDate={startDate}
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                format="YYYY-MM-DD"
                                                value={dueDate}
                                                onChange={(newValue) => setDueDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                {/* Production Supervisor */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('production_supervisor')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disabled={isDisabled}
                                            disablePortal
                                            disableClearable
                                            id="production-supervisor-autocomplete"
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            options={productionSupervisors?.map((option) => ({ id: option.id, label: option.name }))}
                                            value={{ id: productionSupervisorId, label: productionSupervisorName }}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key ={`production-supervisor-${option.id}`}>
                                                        {option.label}
                                                    </li>
                                                )
                                            }}
                                            onChange={(a, b) => {setProductionSupervisorId(b?.id || ''); setProductionSupervisorName(b?.label || '')} }
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </div>

                                <div className='w-full'>
                                    <div className='flex justify-between items-center'>
                                        <p className="text-[14px] text-[#718096]">{t('comments_and_special_instructions')}</p>
                                        <CommentHistoryButton
                                            badgeContent={commentHistory?.length ?? ''}
                                            backgroundColor='#1ab800'
                                            onClick={() => setOpenCommentHistory(true)}
                                        />
                                    </div>

                                    <TextField
                                        disabled={isDisabled}
                                        multiline
                                        rows={3}
                                        value={comments}
                                        onChange={e => setComments(e.target.value)}
                                        sx={{
                                            width: '100%',
                                            background: '#FCFCFC',
                                            '.MuiOutlinedInput-input': {
                                                padding: '9px !important'
                                            },
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: '1px solid #EEEFF2 !important'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className='bg-white p-6 pb-[12px] pt-0 mb-3 rounded-md w-full h-fit'
                        style={{
                            boxShadow: `0px 1px 2px 0px #1018280F, 0px 1px 2px 0px #1018280F, 0px -1px 4px 0px #1018280F`
                        }}
                    >
                        <div className='pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('material_list')}</p>
                        </div>

                        <div className='flex justify-end p-5 pt-0 border-b'>
                            <Tooltip disableInteractive title={!hasEnabledMaterials ? t('no_sub_assemblies_found') : t('add_new_line')} placement='left'>
                                <div>
                                    <AddButton disabled={!hasEnabledMaterials || isDisabled} onClick={handleAddMaterial}><i className="fa-solid fa-plus"></i></AddButton>
                                </div>
                            </Tooltip>
                        </div>

                        {/* MATERIAL LINES */}
                        <div className='border-b border-l border-r rounded'>
                            {
                                materials.length > 0 && !isDisabled ?
                                    <div className='flex justify-end w-full p-2'>
                                        <button disabled={isDisabled} className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={handleClearAllMaterials}>{t('clear_all')}</button>
                                    </div>
                                    :
                                    null
                            }
                            <Materials items={materials} columns={isDisabled ? materialsColumns.filter((column) => column.field !== 'actions') : materialsColumns} random={random} />
                        </div>

                        <div className='flex justify-end w-full p-5'>
                            <div className='flex flex-col gap-y-4 w-full sm:w-1/3'>
                                <div className='flex justify-between items-center'>
                                    <p className="font-[400] text-[#88909C] text-[14px]">{t('total_cost')}</p>
                                    <p className='font-[500] text-[16px]'>{currencySymbol}{totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <p className="font-[400] text-[#88909C] text-[14px]">{t('totalQty')}</p>
                                    <p className='font-[500] text-[16px]'>{totalQty}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isDisabled && (
                        <div className='w-full h-fit'>
                            <div className='w-full flex justify-end'>
                                <button type="button" onClick={updateProductionOrder} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                                    <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </Box>
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

export default UpdateProductionOrder

const MaterialNo = ({ params, t, materialsList, setMaterialsList, handleSelectMaterial, isDisabled }) => {
    const [material, setMaterial] = useState(' ')
    const [exists, setExists] = useState(true)

    const handleChange = (e) => {
        setMaterial(prev => {
            if (prev == ' ') {
                return prev
            }
            if (prev) {
                prev.disabled = false
            }
            return prev
        })
        setMaterial(e.target.value)
        handleSelectMaterial(e.target.value, params.row.id)
        setMaterialsList(prev => {
            const target = prev.find(m => m.id == e.target.value.id)
            target.disabled = true
            return prev
        })
    }

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(isValid(params.row.sub_assembly_id)) {
                const target = materialsList?.find((m) => m.id === params.row.sub_assembly_id)
                if(target) {
                    target.disabled = true
                    setMaterial(target)

                    setExists(true)
                } else {
                    setMaterial(' ')
                    setExists(false)
                }
            } else {
                setExists(true)
            }
        }
    }, [materialsList])

    return (
        <>
            {exists ? (
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <Select disabled={isDisabled} defaultValue=' ' value={material} onChange={e => handleChange(e)}>
                        <MenuItem value=' ' disabled sx={{ fontStyle: 'italic', display: 'none' }}>{t('select_item')}</MenuItem>
                        {
                            materialsList?.map((m, index) => (
                                <MenuItem value={m} key={`materialslist-${m.id}`} disabled={m.disabled}>{m.item_no || m.sub_assembly_no}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    type="text"
                    variant='standard'
                    disabled
                    className='w-full mb-5 px-0 pt-0'
                    value={params.row.sub_assembly_no}
                />
            )}
        </>
    )
}

const MaterialDescription = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.row.description}
            disabled
        />
    )
}

const MaterialQtyPerAssembly = ({ params, t, materialsList, handleMaterialQtyPerAssembly, isDisabled }) => {
    const [qty, setQty] = useState(params.value)
    const [debouncedQty] = useDebounce(qty, 250)
    const [disabled, setDisabled] = useState(true)

    const handleQty = (e) => {
        const regex = /^(-?\d{0,5})$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty('')
            } else {
                setQty(e.target.value)
            }
        }
    }

    useEffect(() => {
        if(debouncedQty && isFinite(debouncedQty)) {
            handleMaterialQtyPerAssembly(toNumber(debouncedQty), params.row.id)
        } else {
            handleMaterialQtyPerAssembly(0, params.row.id)
        }
    }, [debouncedQty])

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(isValid(params.row.sub_assembly_id)) {
                const target = materialsList?.find((m) => m.id === params.row.sub_assembly_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    return (
        <TextField
            disabled={disabled || isDisabled}
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            onChange={(e) => { handleQty(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const MaterialQty = ({ params, t, qtyToProduce }) => {
    const [qty, setQty] = useState(0)

    useEffect(() => {
        setQty(params.row.qty_per_assembly * qtyToProduce)
    }, [params.row.qty_per_assembly, qtyToProduce])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={qty}
            disabled
        />
    )
}

const MaterialUnitOfMeasure = ({ params, t }) => {
    return (
        <TextField
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={params.row.sub_assembly_unit_of_measure_code}
            disabled
        />
    )
}

const MaterialScrap = ({ params, t, materialsList, handleMaterialScrap, isDisabled }) => {
    const [scrap, setScrap] = useState(params.value)
    const [debouncedScrap] = useDebounce(scrap, 250)
    const [disabled, setDisabled] = useState(true)

    const handleScrap = (e) => {
        const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/i
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setScrap('')
            } else if(e.target.value > 100) {
                Swal.fire({
                    icon: "warning",
                    text: t('scrap_cannot_exceed_x_pct', {x: 100}),
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
                setScrap(100)
            } else {
                setScrap(e.target.value)
            }
        }
    }

    useEffect(() => {
        if(debouncedScrap && isFinite(debouncedScrap)) {
            handleMaterialScrap(toNumber(debouncedScrap), params.row.id)
        } else {
            handleMaterialScrap(0, params.row.id)
        }
    }, [debouncedScrap])

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(isValid(params.row.sub_assembly_id)) {
                const target = materialsList?.find((m) => m.id === params.row.sub_assembly_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    return (
        <TextField
            disabled={disabled || isDisabled}
            type="text"
            variant='standard'
            className='w-full mb-5 px-0 pt-0'
            value={scrap}
            onChange={(e) => { handleScrap(e) }}
            onBlur={e => handleZero({
                event: e,
                setter: setScrap
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setScrap,
                value: scrap
            })}
        />
    )
}

const MaterialUnitPrice = ({ params, t, materialsList, currencySymbol, handleMaterialUnitPrice, isDisabled }) => {
    const [unitPrice, setUnitPrice] = useState(params.value)
    const [debouncedUnitPrice] = useDebounce(unitPrice, 250)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        setUnitPrice(params.value)
    }, [params.value])

    useEffect(() => {
        if(debouncedUnitPrice && isFinite(debouncedUnitPrice)) {
            handleMaterialUnitPrice(toNumber(debouncedUnitPrice), params.row.id)
        } else {
            handleMaterialUnitPrice(0, params.row.id)
        }
    }, [debouncedUnitPrice])

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(isValid(params.row.sub_assembly_id)) {
                const target = materialsList?.find((m) => m.id === params.row.sub_assembly_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    return (
        <div className='flex items-center'>
            <span style={{lineHeight: '32px', padding: '4px 0 5px', color: (params.row.item?.id === '' || (disabled || isDisabled)) ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}}>{currencySymbol}</span>
            <TextField
                disabled={disabled || isDisabled}
                type="text"
                variant='standard'
                className='w-full mb-5 px-0 pt-0'
                value={unitPrice}
                onChange={(e) => handleNumber({
                    event: e,
                    setter: setUnitPrice,
                    precision: 2
                })}
                onBlur={e => handleZero({
                    event: e,
                    setter: setUnitPrice
                })}
                onFocus={e => deleteZero({
                    event: e,
                    setter: setUnitPrice,
                    value: unitPrice
                })}
            />
        </div>
    )
}

const MaterialTotalPrice = ({ params, t, currencySymbol, calculateTotalCost, qtyToProduce, isDisabled, materialsList }) => {
    const [totalPrice, setTotalPrice] = useState(0)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (materialsList.length >= 0) {
            if(isValid(params.row.sub_assembly_id)) {
                const target = materialsList?.find((m) => m.id === params.row.sub_assembly_id)

                if(target) {
                    setDisabled(false)
                } else {
                    setDisabled(true)
                }
            } else {
                setDisabled(false)
            }
        }
    }, [materialsList])

    useEffect(() => {
        setTotalPrice(round((params.row.qty_per_assembly * params.row.unit_price) * qtyToProduce, 2))
        calculateTotalCost()
    }, [params.row.qty_per_assembly, params.row.unit_price, qtyToProduce])

    return (
        <span style={{color: (params.row.item?.id === '' || (disabled || isDisabled)) ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}}>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
    )
}

const MaterialActions = ({ params, materials, setMaterials, setMaterialsList, isDisabled }) => {

    const { t } = useTranslation()

    const deleteMaterial = async () => {
        const target = materials.filter(m => m.id !== params.row.id)
        setMaterials(target)
        setMaterialsList(prev => {
            const material = prev.find(m => m.id == params.row.sub_assembly_id)
            if (Boolean(material)) {
                material.disabled = false
            }
            return prev
        })
    }

    return (
        <>
            {!isDisabled ? (
                <div className='flex justify-between'>
                    <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteMaterial}><i className="fa-solid fa-trash"></i></span>
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </>
    )
}
